<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['faceSaggingLocations'])">
      <face-zone-multi-select
        title="label.faceSaggingLocations"
        :value="faceSaggingLocations"
        :options="$options.responseOptions"
        :gender="gender"
        hint="label.selectAllRegionsThatApply"
        field-name="sagging"
        @input="onFieldChange('faceSaggingLocations', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import FaceZoneMultiSelect from '@/modules/questionnaire/new-design-components/questions/face-zone-input/FaceZoneMultiSelect';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';
import { FACE_SAGGING_LOCATION } from '@/modules/questionnaire/api/constants';

const SAGGING_OPTIONS = [
  { value: FACE_SAGGING_LOCATION.EYELIDS, text: 'myFaceZone.eyelids' },
  { value: FACE_SAGGING_LOCATION.EYE_CONTOUR, text: 'myFaceZone.eyeContour' },
  { value: FACE_SAGGING_LOCATION.CHEEKS, text: 'myFaceZone.cheeks' },
  { value: FACE_SAGGING_LOCATION.SIDE_OF_MOUTH, text: 'myFaceZone.sideOfMouth' },
  { value: FACE_SAGGING_LOCATION.NECK, text: 'myFaceZone.neck' }
];

export default {
  name: 'FaceSaggingTemplate',
  components: { QuestionWithErrorWrap, FaceZoneMultiSelect },
  mixins: [stepTemplateMixin],
  responseOptions: SAGGING_OPTIONS,
  props: {
    faceSaggingLocations: {
      type: Array,
      default: () => []
    },
    gender: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped></style>
